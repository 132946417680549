import React from "react";

export default function AuthLink() {
  return (
    <>
      <div className="sign-container flex absolute right-12 top-16 px-7 py-3 rounded-[64px] bg-grey gap-1">
        <div className="column-container column-01">
          <a href="login.js" className="link">
            <span className="heading text-heading">Login</span>
          </a>
        </div>
        <div className="column-container column-02">
          <span className="slash text-heading">/</span>
        </div>
        <div className="column-container column-03">
          <a href="register.js" className="link ">
            <span className="heading text-heading">Sign Up</span>
          </a>
        </div>
      </div>
    </>
  );
}
