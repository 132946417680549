import React from "react";

export default function Footer() {
  return (
    <>
      <footer className="footer-container w-screen flex justify-center items-center fixed bottom-4">
        <span className="copyright text-sm text-black font-normal">
          ©2022 Dearme.club Designed by Artually Studio
        </span>
      </footer>
    </>
  );
}
