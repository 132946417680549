import { React, useRef, useEffect, useState } from "react";
import helpIcon from "./assets/images/help.svg";
import closeIcon from "./assets/images/close-button.svg";

export default function Help() {
  const info = useRef();

  const [helpOpen, setHelpOpen] = useState(false);

  function showInfo() {
    info.current.classList.remove("invisible", "opacity-0");
  }

  function hideInfo() {
    info.current.classList.add("invisible", "opacity-0");
  }

  function swicthInfo() {
    setHelpOpen((helpOpen) => !helpOpen);
    console.log(helpOpen);
  }

  useEffect(() => {
    if (helpOpen) {
      showInfo();
    } else {
      hideInfo();
    }
  }, [helpOpen]);

  return (
    <>
      <div className="help-container absolute right-7 bottom-7 w-fit h-fit">
        <div
          className="help-info-container w-[450px] h-[450px] bg-softwhite rounded-[25px] flex-center px-20 py-10 invisible opacity-0 transition-all"
          ref={info}
        >
          <div
            className="close-help-container absolute right-8 top-8 cursor-pointer"
            onClick={swicthInfo}
          >
            <img src={closeIcon} alt="dear-me-close-icon" />
          </div>
          <span className="help-info text-base font-medium text-textblack">
            Lorem ipsum dolor sit amet consectetur. Cras urna elit id gravida
            pretium elit fames dolor molestie. Morbi facilisi condimentum tempor
            sem a accumsan. Sit penatibus vitae urna gravida tortor iaculis non.
          </span>
        </div>
        <div
          className="help-icon-container w-14 h-14 bg-darkgrey flex-center rounded-64px cursor-pointer absolute right-4 bottom-4"
          onClick={swicthInfo}
        >
          <img src={helpIcon} alt="dear-me-help-icon" />
        </div>
      </div>
    </>
  );
}
