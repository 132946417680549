import { React, useRef, useEffect } from "react";

export default function LanguageSwitcher({ active }) {
  const en = useRef();
  const th = useRef();

  function setLanguageActive(lang) {
    if (lang === "en") {
      en.current.classList.add("language-active");
    } else {
      th.current.classList.add("language-active");
    }
    //eval(`${lang}.current.classList.add("language-active")`);
  }

  useEffect(() => {
    setLanguageActive(active);
  }, []);

  return (
    <>
      <div className="language-container flex absolute left-[60px] bottom-11 px-3 py-2 rounded-[64px] bg-grey gap-1">
        <div className="column-container column-01">
          <a href="en.js" className="link">
            <span className="heading text-heading" ref={en}>
              EN
            </span>
          </a>
        </div>
        <div className="column-container column-02">
          <span className="slash text-heading">/</span>
        </div>
        <div className="column-container column-03">
          <a href="th.js" className="link ">
            <span className="heading text-heading" ref={th}>
              TH
            </span>
          </a>
        </div>
      </div>
    </>
  );
}
