import { React, useState, useEffect, useRef } from "react";
import { DayPicker, useNavigation } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";
import Lottie from "lottie-react";
import AuthLink from "./AuthLink";
import Footer from "./Footer";
import Help from "./Help";
import LanguageSwitcher from "./LanguageSwitcher";
import dearMeHome from "./assets/images/home-page-bg.png";
import dearMeHomeWebp from "./assets/images/home-page-bg.webp";
import dropDown from "./assets/images/drop-down.svg";
import daysIcon from "./assets/images/icon-days.svg";
import daysLoading from "./assets/images/days-loading.json";
import prevArrow from "./assets/images/prev-arrow.svg";

export default function App() {
  const weekDays = ["S", "M", "T", "W", "T", "F", "S"];

  function CustomCaption(props) {
    const { goToMonth, nextMonth, previousMonth } = useNavigation();
    return (
      <>
        <div className="datepicker-head-container flex justify-between mb-9">
          <button
            disabled={!previousMonth}
            onClick={() => previousMonth && goToMonth(previousMonth)}
          >
            <img
              src={prevArrow}
              alt="prev-prev-arrow"
              className="prev-arrow"
              width={14}
              height={30}
            />
          </button>
          <span className="month-year-container text-2xl">
            {format(props.displayMonth, "MMMM yyy")}
          </span>
          <button
            disabled={!nextMonth}
            onClick={() => nextMonth && goToMonth(nextMonth)}
          >
            <img
              src={prevArrow}
              alt="prev-next-arrow"
              className="prev-arrow scale-x-[-1]"
              width={14}
              height={30}
            />
          </button>
        </div>
      </>
    );
  }
  function CustomHeadRow() {
    const weekDayElements = weekDays.map((weekDayIn, index) => {
      return (
        <th scope="col" class="rdp-head_cell" key={index}>
          <span aria-hidden="true">{weekDayIn}</span>
        </th>
      );
    });
    return weekDayElements;
  }

  const datepickerRef = useRef();
  const datepickerInsideRef = useRef();
  const [datepicker, setDatepicker] = useState(false);

  const today = new Date();
  const [selectedDay, setSelectedDay] = useState(today);
  const [loadingDays, setLoadingDays] = useState(true);
  const [numberDays, setNumberDays] = useState(0);

  const daysLoadingStyle = {
    height: 48,
    width: 130,
  };

  function handleClickOutside(e) {
    if (
      datepickerInsideRef.current &&
      !datepickerRef.current.contains(e.target)
    ) {
      setDatepicker(false);
    }
  }

  function calculateDays(start, end) {
    var totleTimes = end.getTime() - start.getTime();
    var days = totleTimes / 86400000;
    if (days < 0) return (days = 0);
    return Math.ceil(days);
  }

  async function setDays(day) {
    setSelectedDay(day);
    setLoadingDays(false);
    setNumberDays(calculateDays(today, day));
  }

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, { capture: true });
    return () => {
      document.removeEventListener("click", handleClickOutside, {
        capture: true,
      });
    };
  }, []);

  useEffect(() => {}, [datepicker]);

  useEffect(() => {}, [selectedDay]);

  return (
    <>
      <main className="app-container bg-white w-screen h-screen px-[60px] pt-5 pb-12 flex justify-center items-center">
        <div className="inner-wrapper bg-grey w-full h-full rounded-[60px] relative max-w-[1920px] flex">
          <div className="heading-container absolute left-[60px] top-20">
            <h1 className="heading-title text-[40px] font-semibold text-textblack">
              HI! How are you today?
            </h1>
            <span className="heading-subtitle text-base font-medium">
              Lorem ipsum dolor sit amet consectetur.
              <br />
              Ultrices tempus turpissagittis tortor.
            </span>
          </div>
          <AuthLink />
          <Help />
          <LanguageSwitcher active={"en"} />
          <div className="column-container w-[48%] absolute left-0 bottom-0">
            <picture className="img-container w-full h-auto z-0">
              <source srcSet={dearMeHomeWebp} type="image/webp" />
              <img src={dearMeHome} alt="dear-me-home" />
            </picture>
          </div>
          <div className="column-container w-[42%] absolute right-0 top-1/4">
            <div className="writer-container flex flex-col w-full justify-start">
              <h2 className="writer-title text-xl text-textblack font-medium mb-4">
                Write a letter to yourself in..
              </h2>
              <div
                className="date-button-container bg-softwhite rounded-[25px] w-fit font-normal text-2xl flex-center "
                ref={datepickerRef}
              >
                {!datepicker ? (
                  <>
                    <div
                      className="date-button-inside-container px-32  py-6 flex-center gap-1 cursor-pointer"
                      onClick={() => setDatepicker(true)}
                    >
                      <span className="date-info">Choose a Date</span>
                      <div className="drop-down-container flex-center">
                        <img src={dropDown} alt="dear-me-drop-down" />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="datepicker-container flex-center flex-col pb-11 pt-5 pl-11 pr-6"
                      ref={datepickerInsideRef}
                    >
                      <DayPicker
                        components={{
                          Caption: CustomCaption,
                          HeadRow: CustomHeadRow,
                        }}
                        mode="single"
                        required
                        selected={selectedDay}
                        onSelect={(day) => {
                          setDays(day);
                        }}
                        pagedNavigation
                      />
                      {loadingDays ? (
                        <>
                          <div className="days-container flex-center rounded-[25px] max-w-[130px] h-12 gap-2 w-full">
                            <Lottie
                              animationData={daysLoading}
                              loop={true}
                              style={daysLoadingStyle}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <button className="button-datepicker-container w-full flex-center">
                            <div className="days-container flex-center rounded-[25px] max-w-[130px] h-12 gap-2 w-full">
                              <span className="number-days-container text-days">
                                {numberDays}
                              </span>
                              <span className="text-days-container text-days">
                                Days
                              </span>
                              <div className="icon-days-container">
                                <img
                                  src={daysIcon}
                                  alt="icon-days"
                                  className="icon-days w-3 h-3"
                                />
                              </div>
                            </div>
                          </button>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
